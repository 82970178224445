<template >
  <div class="layout-page">
    <TopBarView />
    <div class="content-wrapper">
      <!-- <Search /> -->
      <!-- Content -->
      <div>
        <div>
          <div class="flex-grow-1 container-p-y px-4">
            <div class="d-flex justify-content-between aling-items-center">
              <div>
                <h4 class="fw-bold py-3 mb-4">
                    <span class="text-muted fw-light">Category</span> Settings
                </h4>
              </div>
              <div v-if="userData.type == 'SUPERADMIN'">
                <router-link to="/category/form" class="btn btn-primary mt-2" >+ Add</router-link>
              </div>
            </div>
            <!-- Basic Layout & Basic with Icons -->
            <div class="">
                    <!-- table  -->
                  <div class="mb-4" v-if="listShow">
                      <div class="text-nowrap">
                        <List />
                      </div>
                  </div>
                  <!-- form..  -->
                  <div v-if="formShow">
                    <Form />
                  </div>  
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <!-- / Footer -->

      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import List from "./Components/List.vue";
import Form from "./Components/Form.vue";
import Footer from "../FooterView.vue";
import TopBarView from "../TopBarView.vue";
import router from "@/router";
import { useStore } from "vuex";
export default defineComponent({
  components: { List, Form,Footer, TopBarView},
  name: "category-view",
  data() {
    const store = useStore();
    let userData = store.state.auth.userData || localStorage.getItem('userData');
    userData = JSON.parse(userData);
    return {
      listShow: true,
      formShow: false,
      userData: userData,
    };
  },
  watch: {
    '$route.params.id': {
      // immediate: true,
      handler(newVal) {
        this.listShow = !this.listShow;
        this.formShow = !this.formShow;
      },
    },
  },
  methods: {
    toggleComponent() {
      this.listShow = !this.listShow; // Toggles the visibility
      this.formShow = !this.formShow; // Toggles the visibility
      if(this.$route.params.id){
        this.listShow = !this.listShow;
        this.formShow = !this.formShow;
        router.push({ name: "category-view" });
      }
    },
  },

  mounted() {
    // Set isLoading to true when the component is mounted
  }
});
</script>