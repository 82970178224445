<template>
  <div class="content-wrapper">
    <div v-if="isDataTrue == true">
      <div v-if="isupdate">
        <div class="container-xxl flex-grow-1 container-p-y" v-if="iscomponent" >
          <div class="d-flex justify-content-between aling-items-center">
            <div>
              <h4 class="fw-bold py-3 mb-4">
                <span class="text-muted fw-light">User</span>List
              </h4>
            </div>
            <div>
              <button class="btn btn-primary mt-2" @click="updateIsDataTrue">
                Add Category
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive text-nowrap">
                      <List @data-emitted="receiveData"/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-xxl flex-grow-1 container-p-y" v-if="!isupdate">
      <div class="d-flex justify-content-between aling-items-center">
        <div>
          <h4 class="fw-bold py-3 mb-4">
            <span class="text-muted fw-light">Update</span>Category
          </h4>
        </div>
        <div>
          <button class="btn btn-primary mt-2" @click="showForm">
            Category List
          </button>
        </div>
      </div>
      <form name="">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="card-body">
                  <div class="row mb-3">
                    <label
                      class="col-sm-12 col-form-label"
                      for="basic-icon-default-fullname"
                      >Category Title</label
                    >
                    <div class="col-sm-12">
                      <div class="input-group input-group-merge">
                        <span
                          id="basic-icon-default-fullname2"
                          class="input-group-text"
                          ><i class="bx bx-buildings"></i
                        ></span>
                        <input
                          type="text"
                          class="form-control"
                          id="basic-icon-default-fullname"
                          placeholder="Category title"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label
                      class="col-sm-12 col-form-label"
                      for="basic-icon-default-company"
                      >Ulr/Slug
                    </label>
                    <div class="col-sm-12">
                      <div class="input-group input-group-merge">
                        <span
                          id="basic-icon-default-company2"
                          class="input-group-text"
                          ><i class="bx bx-buildings"></i
                        ></span>
                        <input
                          type="text"
                          id="basic-icon-default-company"
                          class="form-control"
                          placeholder="Category alias."
                        />
                      </div>
                    </div>
                  </div>
                  <CkEditor value="demo data"></CkEditor>
                  <div class="row mb-3">
                    <label
                      class="col-sm-12 col-form-label"
                      for="basic-icon-default-company"
                      >SEO</label
                    >
                    <div class="col-sm-12">
                      <div class="input-group input-group-merge">
                        <span
                          id="basic-icon-default-company2"
                          class="input-group-text"
                          ><i class="bx bx-buildings"></i
                        ></span>
                        <input
                          type="text"
                          id="basic-icon-default-company"
                          class="form-control"
                          placeholder="Site Slogan/Description !"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label
                      class="col-sm-12 form-label"
                      for="basic-icon-default-phone"
                      >Meta Description</label
                    >
                    <div class="col-sm-12">
                      <div class="input-group input-group-merge">
                        <span
                          id="basic-icon-default-company2"
                          class="input-group-text"
                          ><i class="bx bx-buildings"></i
                        ></span>
                        <input
                          type="text"
                          id="basic-icon-default-phone"
                          class="form-control phone-mask"
                          placeholder="Site Slogan/Description !"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label
                      class="col-sm-12 col-form-label"
                      for="basic-icon-default-email"
                      >Meta Schema</label
                    >
                    <div class="col-sm-12">
                      <div class="input-group input-group-merge">
                        <span class="input-group-text"
                          ><i class="bx bx-envelope"></i
                        ></span>
                        <input
                          type="text"
                          id="basic-icon-default-email"
                          class="form-control"
                          placeholder="Enter Schema !"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="Category-image">
                  <img
                    src="https://www.imageeditexpert.com/uploads/images/about/about.png"
                    alt="n/a"
                    class="slider-setting-img"
                  />
                </div>
                <div class="mb-3">
                  <label for="formFile" class="form-label">Image Choose</label>
                  <input class="form-control" type="file" id="formFile" />
                </div>
                <div class="mb-3">
                  <label for="defaultSelect" class="form-label"
                    >Default select</label
                  >
                  <select id="defaultSelect" class="form-select">
                    <option>Default select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div class="row justify-content-end">
                  <div class="col-sm-12">
                    <button type="submit" class="btn btn-primary">Send</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <AddForm :isDataTrue="isDataTrue" @updateIsDataTrue="updateIsDataTrue" v-else />
    <EditForm :received-data="receivedData|| {}" @update-is-data-true="handleUpdateDataEdit" />    
    <p>Received isDataTrueList in Parent Component: {{ receivedIsDataTrueList  }}</p>
    <p>Received id in Parent Component: {{ receivedId }}</p>
    <footer class="content-footer footer bg-footer-theme">
      <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
        <div class="mb-2 mb-md-0">
          &copy; 2024 ❤️ by
          <a
            href="https://grameen.technology"
            target="_blank"
            class="footer-link fw-bolder"
            >Grameen Communications</a
          >
        </div>
      </div>
    </footer>
    <div class="content-backdrop fade"></div>
  </div>  
</template>

<script lang="ts">
import { defineComponent } from "vue";
import List from "./Components/List.vue";
import AddForm from "./Components/Form.vue";
import EditForm from "./Components/EditForm.vue";
import { category} from "@/api";

export default defineComponent({
  components: { List, AddForm, EditForm },
  name: "user-view",
  data() {
    return {
      siteInfoData: [],
      formData: {
        title: "",
        alias: "",
        description: "",
        meta_des: "",
        meta: "",
      },
      deletevalue: {
        id: "",
        title: "",
      },
      iscomponent: true,
      isupdate: true,
      isLoading: false,
      fullPage: true,
      isdata: true,
      isDataTrue: true, // Data you want to pass
      receivedData: null,
      isDataTrueList: Boolean,
      receivedIsDataTrueList: Boolean,
      receivedId: null,
      isFormVisible: false,
    };
  },

  async created() {
    this.isdata = true;
    this.siteInfoData = await category();
    this.isdata = false;
  },

  methods: {
    updateIsDataTrue() {
      this.isDataTrue = !this.isDataTrue;
      console.log(this.isDataTrue)
    },
    // chageUpdaegt(alldata: any) {
    //   this.isupdate = !this.isupdate;
    //   console.log(alldata);
    // },
    showForm() {
      // Define the functionality of the showForm method here
      // For example, you can toggle a boolean property to show or hide the form
      // Replace 'isFormVisible' with the appropriate boolean property in your component
      this.isFormVisible = !this.isFormVisible;
    },
    async showLoadingOverlay() {
      try {
        // show the loading overlay
        this.isLoading = true;

        // wait for the component to render
        await this.$nextTick();

        // hide the loading overlay
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
    receiveData(data: null) {
      this.receivedData = data;
      console.log(data);
    }
  },

  mounted() {
    // Set isLoading to true when the component is mounted
    //this.showLoadingOverlay();
  },

});
</script>