<template>
    <!-- Layout container -->
    <div>
      <!-- Footer -->
      <footer class="content-footer footer bg-footer-theme">
        <div
          class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column"
        >
          <div class="mb-2 mb-md-0">
          </div>
          <div class=" text-center">
            © 2023, Powered by - &nbsp;
            <a
              href="https://grameen-ehaat.com"
              target="_blank"
              class="footer-link fw-bolder"
              > Grameen eHaat </a
            >
          </div>
        </div>
      </footer>
      <!-- / Footer -->
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FooterView',
  components: {},
  data() {
    return {};
  },
  methods: {},
  setup() {
    return {};
  },
});
</script>
